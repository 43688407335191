<template>
  <div>
    <ChangeLanguage class="change-language-container-login-page" />
    <LoginFormComponent
      ref="loginFormComponent"
      formType="LOGIN"
      :formTitle="$t('LOGIN_PAGE_FORM_TITLE_LOGIN')"
      :btnLabel="$t('LOGIN_PAGE_BUTTON_LOGIN_HISTORY')"
      :label1="$t('LOGIN_PAGE_LABEL_1')"
      :label2="$t('LOGIN_PAGE_LABEL_2')"
      :text6="$t('LOGIN_PAGE_TEXT_6')"
      :rulesEmail="true"
      :rulesRequired="true"
      @login="login"
      :siteKey="siteKey"
    />

    <SmallModalComponent
      @close-modal="onCloseModal"
      @verify-code="verifyCode"
      :showModal="showModal"
      :title="modalTitle"
      :content="modalContent"
      :btnLabel="btnLabel"
      :action="modalAction"
      :isShowInput="isShowInput"
    />
  </div>
</template>

<script>
import SmallModalComponent from '../../components/SmallModalComponent';
import LoginFormComponent from '../../components/LoginFormComponent';
import ChangeLanguage from '@/components/ChangeLanguage';
import { AUTH_REQUEST, AUTH_TWOFA_CONFIRM } from '@/store/actions/auth';
import { mapState } from 'vuex';
import { env } from '@/constants';

const isDisabledCaptcha = env.recaptcha.isDisabled;

export default {
  name: 'Login',
  components: { LoginFormComponent, SmallModalComponent, ChangeLanguage },
  data() {
    return {
      redirect: '/admin',
      showModal: false,
      modalTitle: this.$t('LOGIN_PAGE_TITLE_2FA'),
      modalContent: this.$t('LOGIN_PAGE_CONTENT_CODE'),
      btnLabel: this.$t('LOGIN_PAGE_BUTTON_VERIFY'),
      modalAction: 'VERIFY',
      isShowInput: true,
      email: '',
      password: '',
      captchaToken: '',
      siteKey: process.env.VUE_APP_SITEKEY || '6LeMwqIUAAAAANsGDacL3nksMsczverr3POAikdl',
      isLogin: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      has2FA: (state) => state.auth.twofaVerify.twofa,
    }),
  },
  methods: {
    verifyCode(verifyCode) {
      this.$store.dispatch(AUTH_TWOFA_CONFIRM, verifyCode.toString());
    },

    login({ email, password, captchaToken }) {
      if (!isDisabledCaptcha && !captchaToken) {
        return;
      }

      this.authenticate(email, password, captchaToken);
    },

    authenticate(email, password, captchaToken) {
      this.$refs.loginFormComponent.resetCaptcha();
      const data = {
        email,
        password,
        'g-recaptcha-response': captchaToken,
        redirect: this.redirect,
      };

      if (isDisabledCaptcha) {
        delete data['g-recaptcha-response'];
      }

      this.$store.dispatch(AUTH_REQUEST, data).then(() => {
        this.isLogin = true;
        this.$store.state.auth.isLoginPage = false;
        if (this.has2FA) {
          this.showModal = true;
        }
      });
    },

    onCloseModal() {
      this.isShowInput = true;
      this.showModal = false;
    },
    setRedirect(url) {
      this.redirect = (url && url.fullPath) || '/admin';
    },
  },
};
</script>

<style lang="scss">
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>

